/**
 * Kai Micah Mills Personal Website
 * @returns {JSX.Element}
 * @constructor
 */

import Moment from 'react-moment';
import { useEffect, useState } from "react";
import Konami from 'react-konami-code';
import { Canvas } from "@react-three/fiber";
import { Model } from "./3d/Scene";
import { OrbitControls } from "@react-three/drei";

const App = () => {

  const [projectDetails, setProjectDetails] = useState(false);
  const [largeView, setLargeView] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [active, setActive] = useState('loading');
  const [header, setHeader] = useState(
    {
      title: 'Kai Micah Mills',
      subtitle: 'High school dropout'
    }
  )

  const easterEgg = () => {
    setActive('hidden');
  }

  useEffect(() => {
    document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape') {
          setLargeView(false)
          setActive('kai');
        }
    })
  }, [])

  useEffect(() => {
    switch(active) {
      case 'hydradao':
        setHeader({
          title: 'HydraDAO',
          subtitle: 'Replacement Research',
          status: 'Pre-launch',
          date: '2024 - Present'
        })
        setProjectDetails({
          description: 'Kai co-founded HydraDAO with a similar model to CryoDAO to work on another attempt at overcoming death in our lifetimes: whole body replacement via cloned or synthetic embryo with neocortex knockout for head or brain transplant, followed by progressive brain tissue replacement. An engineering approach that requires fewer fundamental advances in discovery in aging/biology.',
          images: [
            '/hydradao/hydradao-1.jpg',
            '/hydradao/hydradao-2.jpg',
            '/hydradao/hydradao-3.png',
          ]
        })
        break;
      case 'cryodao':
        setHeader({
          title: 'CryoDAO',
          subtitle: 'Cryopreservation Research',
          status: '$20M+ Treasury',
          date: '2023 - Present'
        })
        setProjectDetails({
          description: 'Kai co-initiated CryoDAO alongside Emil Kendziorra, Vincent Weisser, and Eli Mohamad. CryoDAO is a decentralized community of scientists, entrepreneurs, funders and advocates with the goal of funding high-impact research projects in the cryopreservation field. CryoDAO is a BioDAO pioneering decentralized science with a focus on brain cryopreservation.',
          images: [
            '/cryodao/cryodao-1.jpg',
            '/cryodao/cryodao-2.jpg',
            '/cryodao/cryodao-3.jpg',
          ]
        })
        break;
      case 'cryopets':
        setHeader({
          title: 'Cryopets',
          subtitle: 'Cryonics Facility',
          status: 'Pre-seed',
          date: '2022 - Present'
        })
        setProjectDetails({
          description: 'After a decade of building tech companies, Kai finally had the resources, connections and experience he needed to take on a project in the life extension space. Kai started Cryopets with the goal of perfecting whole-body cryopreservation for mammals, starting with pets, directly targeting the problem of death. Cryopets is backed by the Thiel Foundation and others.',
          images: [
            '/cryopets/cryopets-1.jpg',
            '/cryopets/cryopets-2.jpg',
            '/cryopets/cryopets-3.jpg',
          ]
        })
        break;
      case 'branch':
        setHeader({
          title: 'Branch',
          subtitle: 'Game Studio',
          status: '$15 Million Seed',
          date: '2020 - 2021'
        })
        setProjectDetails({
          description: 'Kai co-founded Branch with Dayton Mills in 2020 with a new gaming-inspired vision on remote work. After 6 months of bootstrapping, Branch raised a $1.6 million pre-seed round from Naval Ravikant, Sahil Lavingia, Homebrew and many more, and Connor Hollasch joined as CTO shortly after. Branch later pivoted to a game studio and raised a $12.5M seed round.',
          images: [
            '/branch/branch-1.jpg',
            '/branch/branch-2.jpg',
            '/branch/branch-3.jpg',
          ]
        })
        break;
      case 'sitesire':
        setHeader({
          title: 'Sitesire',
          subtitle: 'Web Development Studio',
          status: 'Closed',
          date: '2018 - 2020'
        })
        setProjectDetails({
          description: 'Regrouping after Konjure, Sitesire became one of the highest rated website development agencies on Upwork, garnered hundreds of thousands of dollars of revenue and created web apps for 50+ projects, including Gartner and other Fortune 500 companies. After a successful run, Kai suspended operations to divert all focus towards Branch.',
          images: [
            '/sitesire/sitesire-1.jpg',
            '/sitesire/sitesire-2.jpg',
            '/sitesire/sitesire-3.jpg',
          ]
        })
        break;
      case 'konjure':
        setHeader({
          title: 'Konjure',
          subtitle: 'Decentralized Website Builder',
          status: 'Closed',
          date: '2017 - 2019'
        })
        setProjectDetails({
          description: 'Combining years of experience in web development and hosting with crypto, Kai co-founded Konjure with Ben Adamsky, a website builder that deployed websites onto a p2p network. Konjure was bootstrapped and made some exciting breakthroughs, but with crypto in such a new, volatile stage, Konjure could not find PMF so Kai decided it was best to focus on new ventures and close it down.',
          images: [
            '/konjure/konjure-1.jpg',
            '/konjure/konjure-2.jpg',
            '/konjure/konjure-3.jpg',
          ]
        })
        break;
      case 'godlycloud':
        setHeader({
          title: 'GodlyCloud',
          subtitle: 'Cloud Computing',
          status: 'Merger',
          date: '2017'
        })
        setProjectDetails({
          description: 'After the success of MyMCServers and DediFire, Kai dove deeper into the cloud computing world to offer hosting services beyond the gaming space. Based in London, GodlyCloud specialized in virtual private servers and managed solutions and had thousands of customers across the world, later merging with another company in the field.',
          images: [
            '/godlycloud/godlycloud-1.jpg',
            '/godlycloud/godlycloud-2.jpg',
            '/godlycloud/godlycloud-3.jpg',
          ]
        })
        break;
      case 'dedifire':
        setHeader({
          title: 'DediFire',
          subtitle: 'Dedicated Server Hosting',
          status: 'Acquired',
          date: '2016 - 2017'
        })
        setProjectDetails({
          description: 'DediFire offered dedicated, web, voice, and game server hosting for 20+ different games. With a foot in the industry already, Kai was able to gain hundreds of clients in countries all around the world. Kai sold DediFire to a UK gaming company and moved on - it later became a notable game hosting company primarily for CS:GO.',
          images: [
            '/dedifire/dedifire-1.jpg',
            '/dedifire/dedifire-2.jpg',
            '/dedifire/dedifire-3.jpg',
          ]
        })
        break;
      case 'mymcservers':
        setHeader({
          title: 'MyMCServers',
          subtitle: 'Game Server Hosting',
          status: 'Acquired',
          date: '2015'
        })
        setProjectDetails({
          description: 'After running various game servers, Kai decided it was time to take it to the next level and start hosting them. Before long, MyMCServers was one of the largest Minecraft server hosting providers in the world with thousands of customers. After only 9 months since launching, MyMCServers was sold to a private buyer.',
          images: [
            '/mymcservers/mymcservers-1.jpg',
            '/mymcservers/mymcservers-2.jpg',
            '/mymcservers/mymcservers-3.jpg',
          ]
        })
        break;
      case 'yeticrew':
        setHeader({
          title: 'Yeti Crew',
          subtitle: 'Web Design Studio',
          status: 'Closed',
          date: '2014 - 2015'
        })
        setProjectDetails({
          description: 'After developing and designing all the websites for his previous projects, Kai set out to make a company out of his new skills. Yeti Crew was a small team devs and designers that built hundreds of websites in the gaming industry. After a very successful run, Kai decided it was best to move on to something bigger.',
          images: [
            '/yeticrew/yeticrew-1.jpg',
            '/yeticrew/yeticrew-2.jpg',
            '/yeticrew/yeticrew-3.jpg',
          ]
        })
        break;
      case 'craftfest':
        setHeader({
          title: 'CraftFest',
          subtitle: 'Game Server',
          status: 'Closed',
          date: '2012 - 2014'
        })
        setProjectDetails({
          description: 'What started out as a single-player world on Minecraft became a popular multiplayer server with mini-games and ranks players could purchase. CraftFest turned into a lot of income for a 13 year old, and required Kai to learn everything from coding plugins to marketing and managing a community. Kai dropped out a few weeks into high school and ran a network of different Minecraft servers.',
          images: [
            '/craftfest/craftfest-1.jpg',
            '/craftfest/craftfest-2.jpg',
            '/craftfest/craftfest-3.jpg',
          ]
        })
        break;
      case 'awards':
        setHeader({
          title: 'Fellowships',
          subtitle: 'Awards & Grants'
        })
        setProjectDetails({
          description: 'Thiel Fellowship (2023), Foresight Fellowship (2022), On Deck Longevity Biotech Fellowship (2021).',
          images: []
        })
        break;
      case 'hm':
        setHeader({
          title: 'Side Projects',
          subtitle: 'Honorable Mentions'
        })
        setProjectDetails({
          description: 'Some side projects over the years include: Vitalism, Antimortality, Longevity Tech Tree, WHICSIC, CovidCamp, Libs vs Cons, Lumberjack Larry, Bioshard, VaultCraft, Age of Alliance, AusPvP, Node24, Gravity Lift, CraftFestMC, TheCodeMC, and FlexGaming. A moment of silence for the hundreds that will never see the light of day.',
          images: []
        })
        break;
      case 'investments':
        setHeader({
          title: 'Investments',
          subtitle: 'Angel Portfolio'
        })
        setProjectDetails({
          description: 'Pallet (2020), Ender (2021), Transistor Bio (2021).',
          images: []
        })
        break;
      case 'personal':
        setHeader({
          title: 'Pictures',
          subtitle: 'Email for Press'
        })
        setProjectDetails({
          description: 'Please contact kaimicahmills@gmail.com for press.',
          images: [
            '/personal/kai-1.jpg',
            '/personal/kai-2.jpg',
            '/personal/kai-3.jpg',
          ]
        })
        break;
      case 'hidden':
        setHeader({
          title: 'Hidden Menu',
          subtitle: 'Nice find lol'
        })
        setProjectDetails({
          description: 'Congratulations on finding the hidden menu! I didn\'t imagine my life going this way... I grew up in a very religious mormon family. Unfortunately, I believe this is the end. This is all we have, and this is all there is. Death is unacceptable. We have to solve it. This is what I will be dedicating the rest of my life to.',
          images: [
            '/hidden/hidden-1.jpg',
            '/hidden/hidden-2.jpg',
            '/hidden/hidden-3.jpg',
          ]
        })
        break;
      default:
        setHeader({
          title: 'Kai Micah Mills',
          subtitle: 'High school dropout'
        })
        setProjectDetails(false)
    }
  }, [active])

  return (
    <>
      {
        largeView ? (
          <div className="large-view">
            <img className="close" src="close.png" alt="Close" onClick={() => setLargeView(false)} />
            <div className="back" onClick={() => setLargeView(false)}></div>
            <img className="main" src={currentImage} alt={currentImage} />
          </div>
        ) : null
      }
      <Konami action={easterEgg}></Konami>
      <div className="overlay"></div>
      <div className="kmm">
        <div className="name">
          <div className="title-cover">
            <h1>{header.title}</h1>
          </div>
          <h3 className="animFade two">{header.subtitle}</h3>
          <h4>{header.status ? '→ ' + header.status + ' ←' : null}</h4>
          <h4>{header.date ? header.date : null}</h4>
        </div>
        <div className="left-bar animFade three">
          <img src="hydradao.png" draggable={false} id="hydradao" alt="HydraDAO" className={active === 'hydradao' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="cryodao.png" draggable={false} id="cryodao" alt="CryoDAO" className={active === 'cryodao' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="cryopets.png" draggable={false} id="cryopets" alt="Cryopets" className={active === 'cryopets' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="branch.png" draggable={false} id="branch" alt="Branch" className={active === 'branch' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="sitesire.png" draggable={false} id="sitesire" alt="Sitesire" className={active === 'sitesire' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="konjure.png" draggable={false} id="konjure" alt="Konjure" className={active === 'konjure' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="godlycloud.png" draggable={false} id="godlycloud" alt="GodlyCloud" className={active === 'godlycloud' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="dedifire.png" draggable={false} id="dedifire" alt="DediFire" className={active === 'dedifire' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="mymcservers.png" draggable={false} id="mymcservers" alt="MyMCServers" className={active === 'mymcservers' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="yeticrew.png" draggable={false} id="yeticrew" alt="Yeti Crew" className={active === 'yeticrew' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="craftfest.png" draggable={false} id="craftfest" alt="CraftFest" className={active === 'craftfest' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
        </div>
        <div className="middle-bar"></div>
        <div className="middle-bar2"></div>
        <div className="right-bar animFade four">
          <img src="home.png" draggable={false} id="kai" alt="Kai Micah Mills" className={active === 'kai' || active === 'loading' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="awards.png" id="awards" alt="awards" className={active === 'awards' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="hm.png" id="hm" alt="hm" className={active === 'hm' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="investments.png" id="investments" alt="investments" className={active === 'investments' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
          <img src="personal.png" id="personal" alt="personal" className={active === 'personal' ? 'active' : ''} onClick={(e) => setActive(e.currentTarget.id)} />
        </div>
        <div className="bottom-left animFade five hoverable" id="age">→ <Moment diff="1998-06-05" unit="days"></Moment> days alive</div>
        <div className="bottom-middle animFade seven">
          <a href="https://x.com/kaimicahmills" target="_blank" rel="noreferrer">
            <img src="/x-twitter.svg" alt="X" />
          </a>
          <a href="https://linkedin.com/in/kaimicahmills" target="_blank" rel="noreferrer">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="https://youtube.com/@kaimicahmills" target="_blank" rel="noreferrer">
            <i className="fa fa-youtube"></i>
          </a>
          <a href="https://github.com/kaimicahmills" target="_blank" rel="noreferrer">
            <i className="fa fa-github"></i>
          </a>
          <a href="https://instagram.com/cryokai" target="_blank" rel="noreferrer">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="mailto:kaimicahmills@gmail.com">
            <i className="fa fa-envelope"></i>
          </a>
        </div>
        <div className="bottom-right animFade six">copyright {new Date().getFullYear()} ←</div>
        {projectDetails ? (
          <div className="gallery">
            <p>{projectDetails.description}</p>
            <div className="images">
              {projectDetails.images.map((image) => {
                return <img src={image} draggable={false} alt={image} key={image} onClick={() => { setLargeView(true); setCurrentImage(image) }} />;
              })}
            </div>
          </div>
        ) : (!projectDetails && active !== 'kai') ? (
          <div className="gallery">
            <div className="loading">
              <h3>Loading...</h3>
              <div className="bar">
                <div className="bar-progress"></div>
              </div>
            </div>
            <div className="mid midIn">
              <Canvas>
                <ambientLight />
                <Model />
                <OrbitControls />
              </Canvas>
            </div>
          </div>
        ) : active === 'kai' ? (
          <div className="mid">
            <Canvas>
              <ambientLight />
              <Model />
              <OrbitControls />
            </Canvas>
          </div>
        ) : (
          <div className="mid">
            <Canvas>
              <ambientLight />
              <Model />
              <OrbitControls />
            </Canvas>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
